.Filters{
    background: #FFFFFF;
    box-shadow: 6px 6px 20px #E8E8E8;
    border-radius: 6px;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include for-phone-and-tablet {
        flex-direction: column;
    }
    .selectWrapper{
        width: 60%;
        display: flex;
        column-gap: 32px;
        align-items: center;
        @include for-phone-and-tablet {
            width: 100%;
            flex-direction: column;
            row-gap: 32px;
            margin-bottom: 32px;
        }
    }
}