.jobs{
    display: flex;
    column-gap: 40px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    row-gap: 40px;
    @include for-phone-and-tablet {
        flex-direction: column;
    }

    &__item{
        @include for-phone-and-tablet {
            width: 100%;
        }
        width: calc(25% - 30px);
        padding: 16px 16px 8px;
        background: #FFFFFF;
        box-shadow: 6px 6px 20px #E8E8E8;
        border-radius: 6px;
        transition: $fast-animation-duration;
        cursor: pointer;
        color: $primary-text-color;
        text-decoration: none;
        &:hover{
            background: $blue;
        }
        &.DEV:hover{
            background: $blue;
        }
        &.DESIGN:hover{
            background: $red;
        }
        &.COLLAB:hover{
            background: $yellow;
        }
        &.OTHER:hover{
            background: $green;
        } 
        &--title{
            font-size: 1.2em;
            font-weight: 900;
            color: $primary-text-color;
            display: flex;
            min-height: 40px;
            align-items: center; 
            .job__item--tag{
                width: 0.6em;
                height: 0.6em;
                aspect-ratio: 1;
                border-radius: 50%;
                margin-right: 8px;
                background: $blue;
                &.DEV{
                    background: $blue;
                }
                &.DESIGN{
                    background: $red;
                }
                &.COLLAB{
                    background: $yellow;
                }
                &.OTHER{
                    background: $green;
                }
            }
        }
        &--footer{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 8px;
            &--infos{
                display: flex;
                align-items: center;
            }
            &--item{
                margin-right: 8px;
                display: flex;
                align-items: center;
                .emoji{
                    margin-right: 4px;
                }
                &:last-child{
                    margin-right: 0;
                    &::before{
                        content: "|";
                        margin-right: 8px;
                        font-weight: 700;
                    }
                }
            }
            &--link{
                img {
                    width: 31px;
                    height: 30px;
                }
            }
        }
        &:hover{
            * {
                color: $light-text-color;
            }
            .job__item--tag{
                background: rgba(255,255,255,0.5);
            }
        }
    }
    .loading{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}