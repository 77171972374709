.ouidouSelect{
    position: relative;
    min-width: 200px;
    width: calc(33.33% -  64px / 3);
    @include for-phone-and-tablet {
        width: 100%;
    }
    &__input{
        padding-right: 30px;
        position: relative;
        padding-bottom: 12px;
        padding-top: 16px;
        cursor: pointer;
        border-radius: 6px 6px 0 0;
        transition: $fast-animation-duration;
        &:hover{
            background-color: #f3f3f3;
        }
        &:after{
            content: "";
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background: url('../../../assets/icons/chevrondown.png') no-repeat center;
            background-size: contain;
            transition: $fast-animation-duration;
        }
        &::before{
            content: "";
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 1px;
            background: $primary-text-color;
            transition: $fast-animation-duration;
        }
        &.focus{
            background-color: #f3f3f3;
            &:after{
                transform: translateY(-50%) rotate(180deg);
            }
            &::before{
                background: $blue;
            }
            + .ouidouSelect__options{
                display: block;
            }
        }
        &--value{
            color: $secondary-text-color;
            font-size: 1em;
            width: 100%;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 4px ;
            pointer-events: none;
            &--selected{
                padding-left: 4px ;
                color: $primary-text-color;
                font-weight: 700;
            }
        }
    }
    &__options{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
        z-index: 1;
        display: none;
        &--item{
            padding: 8px;
            cursor: pointer;
            position: relative;
            z-index: 10;
            transition: $fast-animation-duration;
            &.selected{
                background: $blue;
                color: $light-text-color;
            }
            &:hover{
                background: $blue;
                color: #fff;
            }
            
        }
    }
}