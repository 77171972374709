//Fonts
@import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//Colors and variables
@import './Variables.scss';

//Components
@import './components/Filters/Filters.scss';
@import './components/Filters/Search/Search.scss';
@import './components/Filters/Select/Select.scss';
@import './components/Jobs/Jobs.scss';

//Global styles
body{
    font-family: $primary-font;
    font-size: 16px;
    line-height: 20px;
    color: $primary-text-color;
    max-width: 1500px;
    padding: 0 20px;
    margin: 30px auto 0;
}
*{
    box-sizing: border-box;
}
.emoji {
    width: 1em;
}