.ouidouSearch{
    position: relative;
    min-width: 200px;
    width: 25%;
    @include for-phone-and-tablet {
        width: 100%;
    }
    
    &__wrapper{
        position: relative;
        transition: $fast-animation-duration;
        &:hover{
            background-color: #f3f3f3;
        }
        &:before{
            content: "";
            position: absolute;
            right: 5px;
            top: 50%;
            z-index: 10;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background: url('../../../assets/icons/search.png') no-repeat center;
            background-size: contain;
            transition: $fast-animation-duration;
        }
    }
    &__input{
        padding-right: 30px;
        position: relative;
        padding-bottom: 12px;
        padding-top: 16px;
        border-radius: 6px 6px 0 0;
        color: $primary-text-color;
        font-size: 1em;
        width: 100%;
        font-weight: 500;
        border: none;
        outline: none;
        background-color: transparent;
        padding-left: 4px ;
        border-bottom: 1px solid $primary-text-color;
        &.focus{
            color: $primary-text-color;
            font-weight: 400;
        }
        
        &:focus{
            background-color: #f3f3f3;
            border-color: $blue;
            border-bottom-width: 2px;
        }
    }
}