// Ouidou Colors
$blue: #2962FF;
$yellow: #FBB667;
$red: #EE7F6B;
$green: #009CA6;

//Text colors
$primary-text-color: #383838;
$secondary-text-color: #646464;
$light-text-color: #fff;

//Timings
$fast-animation-duration: 0.2s;
$medium-animation-duration: 0.4s;
$slow-animation-duration: 0.6s;

//Fonts
$primary-font: 'Raleway', sans-serif;
$secondary-font: 'Overpass', sans-serif;

//Media queries
$phone-max-width: 480px;
$tablet-max-width: 700px;
$tablet-xl-max-width: 1024px;
$desktop-min-width: 1280px;


//Mixins for media queries
@mixin for-phone {
    @media (max-width: $phone-max-width) { @content; }
}
@mixin for-phone-and-tablet {
    @media (max-width: $tablet-xl-max-width) { @content; }
}
@mixin for-tablet {
    @media (min-width: $tablet-max-width) and (max-width: $tablet-xl-max-width) { @content; }
}
@mixin for-desktop {
    @media (min-width: $desktop-min-width) { @content; }
}